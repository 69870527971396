<template>
<!--  <component v-if="ticketType" :is="component[ticketType]"></component>-->
  <div></div>
</template>

<script>
import {
  processList,
} from "@/api/workTicket/index"
import {
  getWtTicketDetail,
} from "@/api/workTicket";
import draft from "@/views/plugin/workflow/mixins/draft";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import {mapGetters} from "vuex";
import {processList as getProcessList,detail as getProcessDetail} from "@/api/plugin/workflow/process";
import {getUserInfo} from "@/api/system/user";

export default {
  data() {
    return {
      form:{
        preparationTime:''
      },
      component:{
        '1':'heightWork',
        '2':'wtBaseDirt',
        '3':'hotWork',
        '4':'hoistingWork',
        '5':'limitSpace',
        '6':'electricWork',
        '7':'openCircuitWork',
        '10':'wtBaseBpp',
        '11':'explodeWork',
        '12':'otherWork',
      },
      ticketType:'',
      mode: 'form',
      type: '',
      templateInfo: {},
      roleBox: false,
      processId: '',
      taskId: "",
      formProcess: {
        processId: '',
        deptId: '',
        processBusinessName: ''
      },
      signBox: false,
      noSign: '',
      templateJson: '',
      controlTypes: []
    };
  },

  components: {
    heightWork: ()=>import('@/views/business/jobSlip/jobTicketList/heightWork/index'),
    wtBaseDirt: ()=>import('@/views/business/jobSlip/jobTicketList/wtBaseDirt/index'),
    hotWork: ()=>import('@/views/business/jobSlip/jobTicketList/hotWork/index'),
    hoistingWork: ()=>import('@/views/business/jobSlip/jobTicketList/hoistingWork/index'),
    limitSpace: ()=>import('@/views/business/jobSlip/jobTicketList/limitSpace/index'),
    electricWork: ()=>import('@/views/business/jobSlip/jobTicketList/electricWork/index'),
    openCircuitWork: ()=>import('@/views/business/jobSlip/jobTicketList/openCircuitWork/index'),
    wtBaseBpp: ()=>import('@/views/business/jobSlip/jobTicketList/wtBaseBpp/index'),
    explodeWork: ()=>import('@/views/business/jobSlip/jobTicketList/explodeWork/index'),
    otherWork: ()=>import('@/views/business/jobSlip/jobTicketList/otherWork/index'),
  },
  mixins: [exForm, draft],
  computed: {
    ...mapGetters(['userInfo']),
    headBtnOptions() {

    },
  },
  created() {
    //this.$router.push({path: "/business/safetyenment/train/trainRecordScores/edit",})
    let url = window.location.href;
    let p = url.split("?")[1];
    let id = "";
    let type = "view";
    if (p) {
      let query = new URLSearchParams(p);
      console.log(query,"query")
      id = query.get("id");
      type = query.get("type");
    } else {
      id = this.$route.query.id;
      type = this.$route.query.type;
    }
    this.type = type;

    // 获取可申请流程列表
    getProcessList(1, 99, {}).then((res) => {
      let process = res.data.data.records.filter(
        (item) => item.key == 'testTemplate'
      )[0];
      this.processId = process.id;
      this.formProcess.processId = this.processId;
    });

    if (["view", "workflow"].includes(this.type)) {
      this.readonly = true;
    } else {
      this.readonly = false;
    }
    const today = new Date(); // 获取当前日期
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    this.form.preparationTime = `${year}-${month}-${day}`; // 将日期转换为字符串格式并设置到 this.form.preparationTime

    if (this.$route.query.type != 'add') return
    getUserInfo().then(res => {
      this.form.reportDept = res.data.data.deptName
      try {
        this.form.applicantPerson = JSON.parse(res.data.data.realName).zh
      } catch (e) {
        this.form.applicantPerson = res.data.data.realName
      }
    })
    this.form.annualPlan = year.toString()

  },
  mounted() {

    window.addEventListener("message", this.receiveMessage, false);
    if (this.$route.query.id || this.form.id) {
      //查询作业票详情
      getWtTicketDetail(""+this.$route.query.id || "" + this.form.id).then(res=>{
        console.log("进行输出------------",res.data.data.statuSort)
        // this.ticketType=res.data.data.ticketType
        if(res.data.data.statuSort=='2'){
          this.$router.push( {
            path: `/jobSlip/jobTicketList/offline`,
            query: {
              type: 'workflow',
              id:this.$route.query.id
            },
          })
        }else {
          this.getTicketList(res.data.data.ticketType);
          //获取到数据后 获取该作业票类型  根据类型跳转页面
        }
      })
    }
  },

  methods: {

    getTicketList(ticketType) {
      if (ticketType == "1") {

         this.$router.push( {
          path: `/jobSlip/jobTicketList/heightWork`,
          query: {
            type: 'workflow',
            id:this.$route.query.id
          },
        })
      } else if (ticketType == "2") {

         this.$router.push( {
          path: `/jobSlip/jobTicketList/wtBaseDirt`,
          query: {
            type: 'workflow',
            id:this.$route.query.id
          },
        })
      } else if (ticketType == "3") {

         this.$router.push( {
          path: `/jobSlip/jobTicketList/hotWork`,
          query: {
            type: 'workflow',
            id:this.$route.query.id
          },
        })
      } else if (ticketType == "4") {

         this.$router.push( {
          path: `/jobSlip/jobTicketList/hoistingWork`,
          query: {
            type: 'workflow',
            id:this.$route.query.id
          },
        })
      } else if (ticketType == "5") {

         this.$router.push( {
          path: `/jobSlip/jobTicketList/limitSpace`,
          query: {
            type: 'workflow',
            id:this.$route.query.id
          },
        })
      } else if (ticketType == "6") {

         this.$router.push( {
          path: `/jobSlip/jobTicketList/electricWork`,
          query: {
            type: 'workflow',
            id:this.$route.query.id
          },
        })
      } else if (ticketType == "7") {

         this.$router.push( {
          path: `/jobSlip/jobTicketList/openCircuitWork`,
          query: {
            type: 'workflow',
            id:this.$route.query.id
          },
        })
      } else if (ticketType == "10") {

         this.$router.push( {
          path: `/jobSlip/jobTicketList/wtBaseBpp`,
          query: {
            type: 'workflow',
            id:this.$route.query.id
          },
        })
      } else if (ticketType == "11") {
        this.$router.push( {
          path: `/jobSlip/jobTicketList/explodeWork`,
          query: {
            type: 'workflow',
            id:this.$route.query.id
          },
        })

      } else if (ticketType == "12") {
        this.$router.push( {
          path: `/jobSlip/jobTicketList/otherWork`,
          query: {
            type: 'workflow',
            id:this.$route.query.id
          },
        })

      }
    },
    receiveMessage(event) {


    },

    getProcessList() {
      processList(1, 9999).then(res => {
        if (res.data.code == 200) {
          let list = res.data.data.records.filter(
            (item) => item.category == '作业票'
          )
          list.forEach(item => {
            if (item.key == this.templateInfo.processKey) {
              this.processId = item.id
            }
          })
        }
      })
    },
    getDetail(templateId) {

    },


    destroyed() {
      // 清除popstate事件 否则会影响到其他页面
      window.removeEventListener("message", this.receiveMessage, false);
    },
  }
}
</script>

<style scoped lang='scss'></style>
